import React from "react";
import Svg from "./svg";

type IconProps = {
  className?: string;
};
const MediaSvg = ({ className }: IconProps) => (
  <Svg
    label="Default Image"
    width="54"
    height="45"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M5 10h44a5 5 0 0 1 5 5v25a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V15a5 5 0 0 1 5-5zm5.075 4a5 5 0 0 0-5 5v16a5 5 0 0 0 5 5h26a5 5 0 0 0 5-5V19a5 5 0 0 0-5-5h-26zm37.282 10c1.688 0 3.056-1.343 3.056-3s-1.368-3-3.056-3c-1.689 0-3.057 1.343-3.057 3s1.368 3 3.057 3zm-.002 11c1.687 0 3.055-1.343 3.055-3s-1.368-3-3.055-3-3.055 1.343-3.055 3 1.368 3 3.055 3z"
      />
      <path
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.746 2.454l9.15 9.15 4.259.05 9.15-9.15"
      />
    </g>
  </Svg>
);

export default MediaSvg;
