import partition from "lodash/partition";
import { MHMediaObject, MHImage } from "./../@types/api";

export const getFormattedDuration = (item: MHMediaObject) => {
  if (item.mhid) {
    const { duration } = item;
    return {
      hours: Math.floor(duration / 60),
      minutes: duration % 60,
    };
  }
};

export const getReleaseYear = (item: MHMediaObject) => {
  if (item.mhid) {
    return new Date(item.releaseDate * 1000).getUTCFullYear().toString();
  }
};

export const getContentTitle = (item) => {
  let contentTitle = item.name;
  if (!item.forceDisplay && item.mhid.startsWith("mhsep")) {
    try {
      const seriesName = item.primaryGroup.primaryGroup.name;
      const episodeNumber = item.primaryGroup.context.position;
      const seasonNumber = item.primaryGroup.primaryGroup.context.position;
      contentTitle = `${seriesName} S${seasonNumber}E${episodeNumber}: ${contentTitle}`;
    } catch (e) {
      // If we can't get this data, that's fine just fall back on episode name
    }
  }
  return contentTitle;
};

export enum ImageSize {
  SMALL,
  MEDIUM,
  LARGE,
  ORIGINAL,
}
const imageSizeKeyLookup = {
  [ImageSize.SMALL]: "small",
  [ImageSize.MEDIUM]: "medium",
  [ImageSize.LARGE]: "large",
  [ImageSize.ORIGINAL]: "original",
};

export type Image = {
  isDefault: boolean;
  width: number;
  height: number;
  url: string;
};
const getImage = (
  object: MHImage,
  size: ImageSize = ImageSize.MEDIUM
): Image => {
  const image = object[imageSizeKeyLookup[size]] || {};

  const { width = 0, height = 0, url = "" } = image;

  return {
    isDefault: object.isDefault || url === "",
    width,
    height,
    url,
  };
};

export const getPosterImage = (
  content: MHMediaObject | { [k: string]: null },
  size: ImageSize = ImageSize.MEDIUM
): Image => {
  const object = content.mhid.startsWith("mhsss")
    ? content.secondaryImage
    : content.primaryImage;

  const { width = 0, height = 0, url = "" } = getImage(object, size);

  return {
    isDefault: object.isDefault || url === "",
    width,
    height,
    url,
  };
};
export const getBannerImage = (
  content: MHMediaObject | { [k: string]: null },
  size: ImageSize = ImageSize.MEDIUM
): Image => {
  const bannerObject = content.mhid.startsWith("mhsss")
    ? content.primaryImage
    : content.secondaryImage;
  const posterObject = content.mhid.startsWith("mhsss")
    ? content.secondaryImage
    : content.primaryImage;

  let image;
  if (!bannerObject.isDefault) {
    image = getImage(bannerObject, size);
  }

  if ((!image || !image.url) && !posterObject.isDefault) {
    image = getImage(posterObject, size);
  }

  const { width = 0, height = 0, url = "" } = image || {};
  return {
    isDefault: url === "",
    width,
    height,
    url,
  };
};

export const getContributorContributions = (contributor): string[] => {
  /**
   * If person is only a cast member, show their cast member role.
    If person is only crew member, show their crew contribution
    If person is director/writer and cast member, just show Director.
    If person is other crew (besides director/writer) and cast member, just show the cast member role.
   */
  const { relationships } = contributor?.context;
  const [rawCastContributions, rawCrewContributions] = partition(
    relationships,
    (o) => o.contribution === "Cast" && o.role
  );
  const crewContributions = rawCrewContributions.map((o) => o.contribution);
  const castContributions = rawCastContributions.map((o) => o.role);

  if (relationships.length === 0) {
    return [];
  } else if (crewContributions.length > 0) {
    const keyCrewContributions = crewContributions.filter(
      (c) => c === "Director" || c === "Writer"
    );
    if (keyCrewContributions.length > 0) {
      return keyCrewContributions;
    } else if (castContributions.length > 0) {
      return castContributions;
    } else {
      return crewContributions;
    }
  }
  return castContributions;
};

export const getGroupedContributors = (contributors) =>
  contributors.reduce((acc, val) => {
    const relationships = val.context.relationships;

    // Remove context since we're rebuilding it in 'characters' and 'credits'
    const { context, ...restContributor } = val;

    const characters = relationships
      .reduce((acc, val) => {
        if (val.role) {
          acc.push(val.role);
        }
        return acc;
      }, [])
      .join(", ");

    relationships.forEach((r) => {
      if (!acc[r.contribution]) {
        acc[r.contribution] = [];
      }

      acc[r.contribution].push({
        ...restContributor,
        characters,
        // credits: relationships
        //   .map((r) => r.contribution)
        //   .sort()
        //   .join(", "),
      });
    });

    return acc;
  }, {});
