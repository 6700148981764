import { useState, useEffect } from "react";

import useTheme from "@/hooks/use-theme";

/*
  - Until we reach the MAX of value x, do this thing
  - Beginning at the MIN of value x, do this thing
*/
type MediaQueryType = {
  MIN?: boolean;
  MAX?: boolean;
};

type WindowSize = {
  width?: number;
  height?: number;
  isMobileL?: MediaQueryType;
  isTabletS?: MediaQueryType;
};

// Hook
function useWindowSize() {
  const theme = useTheme();
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  /*
    - Until we reach the MAX of value x, do this thing
    - Beginning at the MIN of value x, do this thing
  */
  const buildConstraints = (size) => ({
    MIN: windowSize.width > size,
    MAX: windowSize.width <= size,
  });

  return {
    isMobileL: buildConstraints(theme.PhoneMaxWidth),
    isTabletS: buildConstraints(theme.MaxWidthSmall),
    isTabletL: buildConstraints(theme.MobileMaxWidth),
    ...windowSize,
  };
}

export default useWindowSize;
