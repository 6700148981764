import React from "react";
import styled, { css } from "styled-components";

import MediaSvg from "../assets/media-svg";
import IconUser from "../assets/icons/icon-user";

const DefaultImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

type DefaultImageContainerProps = {
  $includeTitle?: boolean;
  $hideBackground?: boolean;
};
const DefaultImageContainer = styled.div<DefaultImageContainerProps>`
  ${(props) => css`
    /* Because the image can be as small as search and as large as poster, a padding in px does now work or the default icon comes out either huge or too small */
    padding: 2%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props.$hideBackground
      ? "transparent"
      : props.theme.Color.PosterDefaultColor};
    box-sizing: border-box;
    color: ${props.theme.Color.GradientColorBottom};

    display: flex;

    & > svg {
      width: 70%;
      height: auto;
      ${props.$includeTitle &&
      css`
        margin-bottom: ${props.theme.Padding}px;
      `}
    }
  `}
`;

const Empty = styled.p`
  color: ${(props) => props.theme.Color.SecondaryColor};
  text-align: center;
`;

export const ImageContainer = styled.div`
  background-color: ${(props) => props.theme.Color.PosterDefaultColor};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type MhImgProps = {
  alt: string;
  isDefault?: boolean;
  isPerson?: boolean;
  src: string;
  className?: string;
  includeTitle?: boolean;
  emptyText?: string;
  showIcon?: boolean;
  children?: React.ReactNode;
  hideBackground?: boolean;
};
const MhImg = ({
  alt,
  isDefault = false,
  isPerson,
  src,
  className,
  includeTitle = false,
  emptyText,
  showIcon = true,
  hideBackground,
  children,
}: MhImgProps) => {
  let image;
  let additionalContent;
  if (emptyText) {
    image = (
      <DefaultImage>
        <DefaultImageContainer
          $includeTitle={includeTitle}
          $hideBackground={hideBackground}
        >
          {showIcon && <MediaSvg />}
          <Empty>{emptyText}</Empty>
        </DefaultImageContainer>
      </DefaultImage>
    );
  } else if (isDefault) {
    additionalContent = children;
    if (isPerson) {
      image = (
        <DefaultImage>
          <DefaultImageContainer
            $includeTitle={includeTitle}
            $hideBackground={hideBackground}
          >
            {showIcon && <IconUser />}
            {includeTitle && alt}
          </DefaultImageContainer>
        </DefaultImage>
      );
    } else {
      image = (
        <DefaultImage>
          <DefaultImageContainer
            $includeTitle={includeTitle}
            $hideBackground={hideBackground}
          >
            {showIcon && <MediaSvg />}
            {includeTitle && alt}
          </DefaultImageContainer>
        </DefaultImage>
      );
    }
  } else {
    additionalContent = children;
    image = (
      <ImageContainer>
        <img src={src} alt={alt} />
      </ImageContainer>
    );
  }

  return (
    <div className={className}>
      {image}
      {additionalContent}
    </div>
  );
};

export default MhImg;
