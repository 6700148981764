import React from "react";
import styled, { css } from "styled-components";

import MhImg from "./controls/mh-img";
import { SizedComponent } from "./silo-components/auto-sizer";
import {
  getBannerImage,
  getPosterImage,
  Image,
  ImageSize,
} from "@/util/get-metadata";
import { MHMediaObject } from "../@types/api";

const SizingConfig = {
  padding: 5,
  minWidth: 75,
};

type ImgContainerProps = {
  width?: number;
};
const ImgContainer = styled.div<ImgContainerProps>`
  min-width: ${SizingConfig.minWidth}px;

  /* Do not add a background color - it messes with default image background colors */
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

type ImgProps = {
  isBanner?: boolean;
};
const Img = styled(MhImg)<ImgProps>`
  padding-bottom: ${(props) =>
    props.isBanner
      ? props.theme.BannerHeightRatio
      : props.theme.PosterHeightRatio}%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    ${(props) => props.isBanner && "object-position: center center;"}
  }
`;

type ImageItemProps = {
  item?: MHMediaObject | { [k: string]: null };
  width?: number;
  children?: React.ReactNode;
  className?: string;
  style?: any; // TODO:
  isPlaceholder?: boolean;
  hideBackground?: boolean;
  showIcon?: boolean;
  isPerson?: boolean;
  isBanner?: boolean;
};

const ImageItem: SizedComponent<ImageItemProps> = ({
  item,
  width,
  children,
  className,
  style,
  isPlaceholder,
  showIcon = true,
  hideBackground,
  isPerson,
  isBanner,
}) => {
  let renderedItem: Partial<Image> = {};
  let movieTitle;

  let posterSize;
  switch (true) {
    case width <= 90:
      posterSize = ImageSize.SMALL;
      break;
    case width > 360:
      posterSize = ImageSize.LARGE;
      break;
    default:
      posterSize = ImageSize.MEDIUM;
  }

  if (!isPlaceholder && item.mhid) {
    renderedItem = isBanner
      ? getBannerImage(item)
      : getPosterImage(item, posterSize);
    movieTitle = item.name;
  }

  if (isPlaceholder) {
    renderedItem.isDefault = true;
  }

  return (
    <ImgContainer className={className} width={width} style={style}>
      <Img
        src={renderedItem.url}
        isDefault={renderedItem.isDefault}
        hideBackground={hideBackground}
        isPerson={isPerson}
        alt={movieTitle}
        isBanner={isBanner}
        showIcon={showIcon}
      >
        {children}
      </Img>
    </ImgContainer>
  );
};

ImageItem.SizingConfig = SizingConfig;

export default ImageItem;
