import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
  preserveAspectRatio?: string;
};
const IconUser = ({ className, preserveAspectRatio }: IconProps) => (
  <Svg
    width="21"
    height="22"
    className={className}
    preserveAspectRatio={preserveAspectRatio}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10.786 0a6.286 6.286 0 0 1 4.75 10.403c3.13.749 5.464 3.642 5.464 7.096V22H.571v-4.5c0-3.455 2.334-6.347 5.465-7.097A6.286 6.286 0 0 1 10.786 0zm3.181 12.54h-2.552a6.36 6.36 0 0 1-1.258 0H7.603c-2.636 0-4.773 2.238-4.773 4.998v2.132h15.91v-2.132c0-2.76-2.137-4.998-4.773-4.998zM10.79 2.26a4.03 4.03 0 0 0-.915 7.956h1.83a4.032 4.032 0 0 0-.915-7.956z"
    />
  </Svg>
);

export default IconUser;
