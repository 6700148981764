import React from "react";

type PluralizeProps = {
  amount: number;
  singular: string;
  plural: string;
  modifierText?: string;
};
export const pluralize = ({
  amount,
  singular,
  plural,
  modifierText = null,
}: PluralizeProps) => {
  const text = amount === 1 ? singular : plural;
  return [`${amount}`, modifierText, text].filter((t) => !!t).join(" ");
};

const Pluralize = (args: PluralizeProps) => {
  return <span>{pluralize(args)}</span>;
};

export default Pluralize;
